import axios from 'axios'
import _ from 'lodash'
import Q from 'q'

import {showLoader, hideLoader} from '../modules/loader'

const {apiUrl} = require('./../../../config.js');

const urlBase = apiUrl + 'api/';
const defaultParams = {
    responseType: 'json',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    params: {}
}

const API = {
    get: (dispatch, call, urlParams = undefined, getParams = {}) => {
        showLoader()(dispatch);
        var deferred = Q.defer();

        let url = urlBase + call;

        if(!_.isUndefined(urlParams)){
            url += "/" + (_.isArray(urlParams) ? urlParams.join('/') : urlParams);
        }

        axios.get(url, {
                ...defaultParams,
                params: getParams
            })
            .then(response => {
                hideLoader()(dispatch);
                deferred.resolve(response.data);
            })
            .catch(error => {
                hideLoader()(dispatch);
                console.log(error);
                deferred.reject(error);
            });
            return deferred.promise;
    },
    post: (dispatch, call, postParams = {}) => {
        showLoader()(dispatch);
        var deferred = Q.defer();

        let url = urlBase + call;

        axios.post(url, postParams, defaultParams)
            .then(response => {
                hideLoader()(dispatch);
                deferred.resolve(response.data);
            })
            .catch(error => {
                hideLoader()(dispatch);
                console.log(error);
                deferred.reject(error);
            });
            return deferred.promise;
    },
    delete: (dispatch, call, urlParams = undefined, getParams = {}) => {
        showLoader()(dispatch);
        var deferred = Q.defer();

        let url = urlBase + call;

        if(!_.isUndefined(urlParams)){
            url += '/';
            url += _.isArray(urlParams) ? urlParams.join('/') : urlParams;
        }

        axios.delete(url, {
                ...defaultParams,
                params: getParams
            })
            .then(response => {
                hideLoader()(dispatch);
                deferred.resolve(response.data);
            })
            .catch(error => {
                hideLoader()(dispatch);
                console.log(error);
                deferred.resolve(error.response);
            });
            return deferred.promise;
    },
}
export default API;
