import { combineReducers } from 'redux'

import main from './main'
import modal from './modal'
import request from './request'
import loader from './loader'

export default combineReducers({
    main,
    modal,
    request,
    loader
})
