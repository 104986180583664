export const SETVIDEO = 'modal/SETVIDEO'
export const SETSCHUDULE = 'modal/SETSCHUDULE'
export const SETMARKDOWN = 'modal/SETMARKDOWN'
export const SETPETITIONNOTFOUND = 'modal/SETPETITIONNOTFOUND'
export const SHOW = 'modal/SHOW'
export const HIDE = 'modal/HIDE'
export const DESTROY = 'modal/DESTROY'

const initialState = {
    idVideo: undefined,
    schudule: undefined,
    markdown: undefined,
    show : false,
    empty: true,
    withAnimation: true,
    petitionNotFound: undefined
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SETVIDEO:
            return {
                ...state,
                idVideo: action.playload,
                empty: false,
            }
        case SETSCHUDULE:
            return {
                ...state,
                schudule: action.playload,
                withAnimation: false,
                empty: false,
            }
        case SETMARKDOWN:
            return {
                ...state,
                markdown: action.playload,
                withAnimation: false,
                empty: false,
            }
        case SETPETITIONNOTFOUND:
            return {
                ...state,
                petitionNotFound: true,
                empty: false,
            }
        case SHOW:
            document.body.classList.add('modal-open');
            return {
                ...state,
                show: true
            }
        case HIDE:
            return {
                ...state,
                show: false
            }
        case DESTROY:
            document.body.classList.remove('modal-open');
            return {
                ...initialState
            }
        default:
            return state
    }
}

export const setVideo = (id = null) => {
    const playload = id;
    return dispatch => {
        dispatch({
            type: SETVIDEO,
            playload
        })
         return setTimeout(() => {
            dispatch({
                type: SHOW
            })
        }, 200)
    }
}

export const setPetitionNotFound = (dispatch) => {
    dispatch({
        type: SETPETITIONNOTFOUND
    })
    return setTimeout(() => {
        dispatch({
            type: SHOW
        })
    }, 200)
}

export const setSchudule = (dispatch,schudule = {}) => {
    dispatch({
        type: SETSCHUDULE,
        playload: schudule
    })
    return setTimeout(() => {
        dispatch({
            type: SHOW
        })
    }, 200)
}

export const setMarkdown = (markdown) => {
    // var readMarkdown = require('read-markdown')
    // console.log()
    return dispatch => {
        dispatch({
            type: SETMARKDOWN,
            playload: markdown
        })
        return setTimeout(() => {
            dispatch({
                type: SHOW
            })
        }, 200)
    }
}

export const closeModal = () => {
    return dispatch => {
        dispatch({
            type: HIDE
        })

        return setTimeout(() => {
            dispatch({
                type: DESTROY
            })
        }, 300)
    }
}
