import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from '../../locale/en/messages.json';
import es from '../../locale/es/messages.json';

import { defaultLanguage, languages, finalUrl } from '../../config';

const resources = {
  en,
  es,
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    availableLngs: languages,
    fallbackLng: defaultLanguage,
    i18nextOptions: {
        debug: process.env.NODE_ENV === 'development',
    },
    siteUrl: finalUrl,
    redirect: true
  });

  export default i18n;