export const SHOWLOADER = 'loader/SHOWLOADER'
export const HIDELOADER = 'loader/HIDELOADER'

const initialState = {
    visible: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOWLOADER:
            return {
                ...state,
                visible: true
            }
        case HIDELOADER:
            return {
                ...state,
                visible: false
            }
        default:
            return state
    }
}

export const showLoader = () => {
    return dispatch => {
        dispatch({
            type: SHOWLOADER
        })
    }
}

export const hideLoader = () => {
    return dispatch => {
        dispatch({
            type: HIDELOADER
        })
    }
}
