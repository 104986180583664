module.exports = {
    defaultLanguage: "es",
    languages: ["es", "en"],
    siteTitle: "SEAT Factory Tours",
    s3Url: "https://cdn.seatfactoryvisit.com/",
    finalUrl: "https://seatfactoryvisit.com/",
    apiUrl: "https://api.seatfactoryvisit.com/",
    analytics: {
        preview: "//assets.adobedtm.com/launch-EN6d587ada27cc43d2b0798b5d2581e24d-staging.min.js",
        development: "//assets.adobedtm.com/launch-EN6d587ada27cc43d2b0798b5d2581e24d-staging.min.js",
        production: "//assets.adobedtm.com/launch-ENb70d780c40d145d483879179c0a43392.min.js"
    }
}
