const initialState = {
    isDev: process.env.NODE_ENV === 'development',
    links: {
        seat: "http://www.seat.es",
        xxss: {
            fb: "https://www.facebook.com/seat?fref=ts",
            tw: "https://twitter.com/tuseat",
            go: "https://plus.google.com/+SEATes/posts",
            yt: "https://www.youtube.com/user/tuseat"
        }
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        default:
            return state
    }
}

export const getUrl = (withDomain = false) => {
    return (dispatch, getState) => {
        if(typeof window !== 'undefined'){
            return withDomain ? window.location.href : window.location.pathname;
        }
        return "";
    }
}
